@import url('https://fonts.googleapis.com/css?family=Raleway');

.center{
    font-family: 'Raleway', sans-serif;
    background-color: black;
    
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    /* background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255,255,255,0.8)), url("./tl_logo.jpg") no-repeat;
    background-size: 400px 255px; */
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: black;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}