.middle {
    position: absolute;
    top: 50%;
    left: 50%;
}

.stepper {
    height: 100%;
}

.padded {
    padding: 10px;
    /* margin-top: 50px */
}

.label {
    border: 10px;
}

.checked {
    background-color: lightgreen;

}

.unchecked {
    background-color: lightsalmon;
}

.bottomBar {
    background-color: lightgoldenrodyellow
}

.table-header {
    background-color: '#fff';
    position: 'sticky';
    top: 0;
    z-index: 10000000;
}

.confirm-button {
    height: 2;
    width: 2;
}

.relative{
    transform: translateX(0);
    
  }

.ticket-button {
    align-self: flex-end;
}